
// @ts-nocheck
import locale__var_www_vhosts_estateinvest_md_httpdocs_locales_en_ts from "../locales/en.ts?hash=a53978bd&locale=en";
import locale__var_www_vhosts_estateinvest_md_httpdocs_locales_ro_ts from "../locales/ro.ts?hash=c654feb7&locale=ro";
import locale__var_www_vhosts_estateinvest_md_httpdocs_locales_ru_ts from "../locales/ru.ts?hash=ba894951&locale=ru";


export const localeCodes =  [
  "en",
  "ro",
  "ru"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.ts", load: () => Promise.resolve(locale__var_www_vhosts_estateinvest_md_httpdocs_locales_en_ts), cache: false }],
  "ro": [{ key: "../locales/ro.ts", load: () => Promise.resolve(locale__var_www_vhosts_estateinvest_md_httpdocs_locales_ro_ts), cache: false }],
  "ru": [{ key: "../locales/ru.ts", load: () => Promise.resolve(locale__var_www_vhosts_estateinvest_md_httpdocs_locales_ru_ts), cache: false }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "en",
      "name": "English",
      "iso": "en-US",
      "files": [
        "locales/en.ts"
      ]
    },
    {
      "code": "ro",
      "name": "Română",
      "iso": "ro-RO",
      "files": [
        "locales/ro.ts"
      ]
    },
    {
      "code": "ru",
      "name": "Русский",
      "iso": "ru-RU",
      "files": [
        "locales/ru.ts"
      ]
    }
  ],
  "defaultLocale": "ro-RO",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": false,
  "langDir": "locales",
  "rootRedirect": "ro",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const normalizedLocales = [
  {
    "code": "en",
    "name": "English",
    "iso": "en-US",
    "files": [
      {
        "path": "locales/en.ts"
      }
    ]
  },
  {
    "code": "ro",
    "name": "Română",
    "iso": "ro-RO",
    "files": [
      {
        "path": "locales/ro.ts"
      }
    ]
  },
  {
    "code": "ru",
    "name": "Русский",
    "iso": "ru-RU",
    "files": [
      {
        "path": "locales/ru.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const STRATEGIES = {
  "PREFIX": "prefix",
  "PREFIX_EXCEPT_DEFAULT": "prefix_except_default",
  "PREFIX_AND_DEFAULT": "prefix_and_default",
  "NO_PREFIX": "no_prefix"
}
export const DEFAULT_LOCALE = ""
export const DEFAULT_STRATEGY = "prefix_except_default"
export const DEFAULT_TRAILING_SLASH = false
export const DEFAULT_ROUTES_NAME_SEPARATOR = "___"
export const DEFAULT_LOCALE_ROUTE_NAME_SUFFIX = "default"
export const DEFAULT_DETECTION_DIRECTION = "ltr"
export const DEFAULT_BASE_URL = ""
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"


