import revive_payload_client_4sVQNw7RlN from "/var/www/vhosts/estateinvest.md/httpdocs/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/vhosts/estateinvest.md/httpdocs/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/vhosts/estateinvest.md/httpdocs/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/vhosts/estateinvest.md/httpdocs/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/vhosts/estateinvest.md/httpdocs/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/vhosts/estateinvest.md/httpdocs/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/vhosts/estateinvest.md/httpdocs/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/vhosts/estateinvest.md/httpdocs/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_RdadCF4ArJ from "/var/www/vhosts/estateinvest.md/httpdocs/node_modules/nuxt-aos/dist/runtime/plugin.mjs";
import plugin_tMGwffvjBc from "/var/www/vhosts/estateinvest.md/httpdocs/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import i18n_yfWm7jX06p from "/var/www/vhosts/estateinvest.md/httpdocs/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/vhosts/estateinvest.md/httpdocs/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import drag_scroll_client_GWnOEKIEnx from "/var/www/vhosts/estateinvest.md/httpdocs/plugins/drag-scroll.client.ts";
import simple_parallax_client_oo2oDZLnzV from "/var/www/vhosts/estateinvest.md/httpdocs/plugins/simple-parallax.client.ts";
import yandex_map_client_9cl7nhyqlk from "/var/www/vhosts/estateinvest.md/httpdocs/plugins/yandex-map.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_RdadCF4ArJ,
  plugin_tMGwffvjBc,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  drag_scroll_client_GWnOEKIEnx,
  simple_parallax_client_oo2oDZLnzV,
  yandex_map_client_9cl7nhyqlk
]