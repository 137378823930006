import { default as about7BS0fdKfAMMeta } from "/var/www/vhosts/estateinvest.md/httpdocs/pages/about.vue?macro=true";
import { default as contactsHD7dWsVTuiMeta } from "/var/www/vhosts/estateinvest.md/httpdocs/pages/contacts.vue?macro=true";
import { default as indexYAsx0JAtEtMeta } from "/var/www/vhosts/estateinvest.md/httpdocs/pages/index.vue?macro=true";
import { default as policygjUeqRIxQDMeta } from "/var/www/vhosts/estateinvest.md/httpdocs/pages/policy.vue?macro=true";
import { default as _91id_93RXqb5NvYcoMeta } from "/var/www/vhosts/estateinvest.md/httpdocs/pages/projects/[id].vue?macro=true";
import { default as indexVqKAqGxlfmMeta } from "/var/www/vhosts/estateinvest.md/httpdocs/pages/projects/index.vue?macro=true";
import { default as successp5AOQtYNbxMeta } from "/var/www/vhosts/estateinvest.md/httpdocs/pages/success.vue?macro=true";
import { default as termsbMNTQP1usXMeta } from "/var/www/vhosts/estateinvest.md/httpdocs/pages/terms.vue?macro=true";
export default [
  {
    name: about7BS0fdKfAMMeta?.name ?? "about___en",
    path: about7BS0fdKfAMMeta?.path ?? "/en/about",
    meta: about7BS0fdKfAMMeta || {},
    alias: about7BS0fdKfAMMeta?.alias || [],
    redirect: about7BS0fdKfAMMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/about.vue").then(m => m.default || m)
  },
  {
    name: about7BS0fdKfAMMeta?.name ?? "about___ro",
    path: about7BS0fdKfAMMeta?.path ?? "/ro/about",
    meta: about7BS0fdKfAMMeta || {},
    alias: about7BS0fdKfAMMeta?.alias || [],
    redirect: about7BS0fdKfAMMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/about.vue").then(m => m.default || m)
  },
  {
    name: about7BS0fdKfAMMeta?.name ?? "about___ru",
    path: about7BS0fdKfAMMeta?.path ?? "/ru/about",
    meta: about7BS0fdKfAMMeta || {},
    alias: about7BS0fdKfAMMeta?.alias || [],
    redirect: about7BS0fdKfAMMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/about.vue").then(m => m.default || m)
  },
  {
    name: contactsHD7dWsVTuiMeta?.name ?? "contacts___en",
    path: contactsHD7dWsVTuiMeta?.path ?? "/en/contacts",
    meta: contactsHD7dWsVTuiMeta || {},
    alias: contactsHD7dWsVTuiMeta?.alias || [],
    redirect: contactsHD7dWsVTuiMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsHD7dWsVTuiMeta?.name ?? "contacts___ro",
    path: contactsHD7dWsVTuiMeta?.path ?? "/ro/contacts",
    meta: contactsHD7dWsVTuiMeta || {},
    alias: contactsHD7dWsVTuiMeta?.alias || [],
    redirect: contactsHD7dWsVTuiMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: contactsHD7dWsVTuiMeta?.name ?? "contacts___ru",
    path: contactsHD7dWsVTuiMeta?.path ?? "/ru/contacts",
    meta: contactsHD7dWsVTuiMeta || {},
    alias: contactsHD7dWsVTuiMeta?.alias || [],
    redirect: contactsHD7dWsVTuiMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: indexYAsx0JAtEtMeta?.name ?? "index___en",
    path: indexYAsx0JAtEtMeta?.path ?? "/en",
    meta: indexYAsx0JAtEtMeta || {},
    alias: indexYAsx0JAtEtMeta?.alias || [],
    redirect: indexYAsx0JAtEtMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexYAsx0JAtEtMeta?.name ?? "index___ro",
    path: indexYAsx0JAtEtMeta?.path ?? "/ro",
    meta: indexYAsx0JAtEtMeta || {},
    alias: indexYAsx0JAtEtMeta?.alias || [],
    redirect: indexYAsx0JAtEtMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexYAsx0JAtEtMeta?.name ?? "index___ru",
    path: indexYAsx0JAtEtMeta?.path ?? "/ru",
    meta: indexYAsx0JAtEtMeta || {},
    alias: indexYAsx0JAtEtMeta?.alias || [],
    redirect: indexYAsx0JAtEtMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/index.vue").then(m => m.default || m)
  },
  {
    name: policygjUeqRIxQDMeta?.name ?? "policy___en",
    path: policygjUeqRIxQDMeta?.path ?? "/en/policy",
    meta: policygjUeqRIxQDMeta || {},
    alias: policygjUeqRIxQDMeta?.alias || [],
    redirect: policygjUeqRIxQDMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: policygjUeqRIxQDMeta?.name ?? "policy___ro",
    path: policygjUeqRIxQDMeta?.path ?? "/ro/policy",
    meta: policygjUeqRIxQDMeta || {},
    alias: policygjUeqRIxQDMeta?.alias || [],
    redirect: policygjUeqRIxQDMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: policygjUeqRIxQDMeta?.name ?? "policy___ru",
    path: policygjUeqRIxQDMeta?.path ?? "/ru/policy",
    meta: policygjUeqRIxQDMeta || {},
    alias: policygjUeqRIxQDMeta?.alias || [],
    redirect: policygjUeqRIxQDMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: _91id_93RXqb5NvYcoMeta?.name ?? "projects-id___en",
    path: _91id_93RXqb5NvYcoMeta?.path ?? "/en/projects/:id()",
    meta: _91id_93RXqb5NvYcoMeta || {},
    alias: _91id_93RXqb5NvYcoMeta?.alias || [],
    redirect: _91id_93RXqb5NvYcoMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93RXqb5NvYcoMeta?.name ?? "projects-id___ro",
    path: _91id_93RXqb5NvYcoMeta?.path ?? "/ro/projects/:id()",
    meta: _91id_93RXqb5NvYcoMeta || {},
    alias: _91id_93RXqb5NvYcoMeta?.alias || [],
    redirect: _91id_93RXqb5NvYcoMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93RXqb5NvYcoMeta?.name ?? "projects-id___ru",
    path: _91id_93RXqb5NvYcoMeta?.path ?? "/ru/projects/:id()",
    meta: _91id_93RXqb5NvYcoMeta || {},
    alias: _91id_93RXqb5NvYcoMeta?.alias || [],
    redirect: _91id_93RXqb5NvYcoMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/projects/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVqKAqGxlfmMeta?.name ?? "projects___en",
    path: indexVqKAqGxlfmMeta?.path ?? "/en/projects",
    meta: indexVqKAqGxlfmMeta || {},
    alias: indexVqKAqGxlfmMeta?.alias || [],
    redirect: indexVqKAqGxlfmMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: indexVqKAqGxlfmMeta?.name ?? "projects___ro",
    path: indexVqKAqGxlfmMeta?.path ?? "/ro/projects",
    meta: indexVqKAqGxlfmMeta || {},
    alias: indexVqKAqGxlfmMeta?.alias || [],
    redirect: indexVqKAqGxlfmMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: indexVqKAqGxlfmMeta?.name ?? "projects___ru",
    path: indexVqKAqGxlfmMeta?.path ?? "/ru/projects",
    meta: indexVqKAqGxlfmMeta || {},
    alias: indexVqKAqGxlfmMeta?.alias || [],
    redirect: indexVqKAqGxlfmMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/projects/index.vue").then(m => m.default || m)
  },
  {
    name: successp5AOQtYNbxMeta?.name ?? "success___en",
    path: successp5AOQtYNbxMeta?.path ?? "/en/success",
    meta: successp5AOQtYNbxMeta || {},
    alias: successp5AOQtYNbxMeta?.alias || [],
    redirect: successp5AOQtYNbxMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successp5AOQtYNbxMeta?.name ?? "success___ro",
    path: successp5AOQtYNbxMeta?.path ?? "/ro/success",
    meta: successp5AOQtYNbxMeta || {},
    alias: successp5AOQtYNbxMeta?.alias || [],
    redirect: successp5AOQtYNbxMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/success.vue").then(m => m.default || m)
  },
  {
    name: successp5AOQtYNbxMeta?.name ?? "success___ru",
    path: successp5AOQtYNbxMeta?.path ?? "/ru/success",
    meta: successp5AOQtYNbxMeta || {},
    alias: successp5AOQtYNbxMeta?.alias || [],
    redirect: successp5AOQtYNbxMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/success.vue").then(m => m.default || m)
  },
  {
    name: termsbMNTQP1usXMeta?.name ?? "terms___en",
    path: termsbMNTQP1usXMeta?.path ?? "/en/terms",
    meta: termsbMNTQP1usXMeta || {},
    alias: termsbMNTQP1usXMeta?.alias || [],
    redirect: termsbMNTQP1usXMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: termsbMNTQP1usXMeta?.name ?? "terms___ro",
    path: termsbMNTQP1usXMeta?.path ?? "/ro/terms",
    meta: termsbMNTQP1usXMeta || {},
    alias: termsbMNTQP1usXMeta?.alias || [],
    redirect: termsbMNTQP1usXMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: termsbMNTQP1usXMeta?.name ?? "terms___ru",
    path: termsbMNTQP1usXMeta?.path ?? "/ru/terms",
    meta: termsbMNTQP1usXMeta || {},
    alias: termsbMNTQP1usXMeta?.alias || [],
    redirect: termsbMNTQP1usXMeta?.redirect,
    component: () => import("/var/www/vhosts/estateinvest.md/httpdocs/pages/terms.vue").then(m => m.default || m)
  }
]